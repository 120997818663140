import { EMPTY_SPECIAL_HANDLING_IND_MAPPING_MODEL } from "presentation/model/SpecialHandlingIndMapping/SpecialHandlingIndMappingModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: SpecialHandlingIndMappingProvider,
    useTracked: useSpecialHandlingIndMappingTracked
} = createContainer(() => useState(EMPTY_SPECIAL_HANDLING_IND_MAPPING_MODEL), {concurrentMode: true});
export { SpecialHandlingIndMappingProvider, useSpecialHandlingIndMappingTracked };

