
export const SpecialHandlingIndMappingConstant = {
    Table: {
        PRIORITY: "Priority",
        SPECIAL_HANDLING_CODE: "Special Handling Code",
        SPECIAL_HANDLING_IND: "Special Handling Ind.",
        ACTIVE_IND: "Active Ind.",
    },
    Title: {
        CATEGORY: "Charge",
        TITLE: "SP Handling Indicator Setting",
    },
}
export const specialHandlingIndMappingRequiredFieldList: string[] = [
    'specialHandlingCodeList',
    'specialHandlingInd',
    'activeInd',
];