import { EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY, SpecialHandlingIndMappingEntity } from "domain/entity/SpecialHandlingIndMapping/SpecialHandlingIndMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface SpecialHandlingIndMappingDropdownOptions {
    specialHandlingDropdownOptions: DropdownProps[],
}
export interface SpecialHandlingIndMappingViewChangeState extends BaseViewChangeSate {
}

export interface SpecialHandlingIndMappingModel {
    isLoading:boolean,
    isShowEditPanel:boolean,
    isAdd:boolean,
    isEdit:boolean,
    dynamicOptions: SpecialHandlingIndMappingDropdownOptions,
    specialHandlingIndMappingList: SpecialHandlingIndMappingEntity[],
    selectedRows: SpecialHandlingIndMappingEntity[],
    updatedRows: SpecialHandlingIndMappingEntity[],
    currentRow: SpecialHandlingIndMappingEntity,
    currentEditRow: SpecialHandlingIndMappingEntity,
    
}

export const EMPTY_SPECIAL_HANDLING_IND_MAPPING_MODEL: SpecialHandlingIndMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        specialHandlingDropdownOptions: [],
    },
    specialHandlingIndMappingList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: {...EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY},
    currentEditRow: {...EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY},
}