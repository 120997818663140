import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { useSpecialHandlingIndMappingTracked } from "presentation/store/SpecialHandlingIndMapping/SpecialHandlingIndMappingProvider";
import { SpecialHandlingIndMappingVM } from "presentation/viewModel/SpecialHandlingIndMapping/SpecialHandlingIndMappingVM";
import { useMemo } from "react";

export const useSpecialHandlingIndMappingVM = () => {
    const [, setSpecialHandlingIndMappingState] = useSpecialHandlingIndMappingTracked();
    const specialHandlingIndMappingVM = useMemo(() =>
        SpecialHandlingIndMappingVM({
            dispatch: [setSpecialHandlingIndMappingState],
            specialHandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
        }), [setSpecialHandlingIndMappingState])

    return specialHandlingIndMappingVM
}