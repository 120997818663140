export interface SpecialHandlingIndMappingEntity {
    key: string,
    versionIdentifier: {
        version?: string
    },
    id: number,
    specialHandlingCode: string,
    specialHandlingCodeList?: string[],
    specialHandlingInd: string,
    mappingType: "SpecialHandlingIndMappingEntity",
    activeInd: string,
    priority: number,
    tempPriority: number,
    effectiveDate?: Date | null,
    expiryDate?: Date | null,
    confirmedDate?: Date | null

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_SPECIAL_HANDLING_IND_MAPPING_ENTITY : SpecialHandlingIndMappingEntity = {
    key: "",
    id: 0,
    versionIdentifier: {},
    specialHandlingCode: "",
    specialHandlingCodeList: [],
    specialHandlingInd: "",
    mappingType: "SpecialHandlingIndMappingEntity",
    activeInd: "Y",
    priority: 0,
    tempPriority: 0,
}