import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { SpecialHandlingIndMappingProvider } from "presentation/store/SpecialHandlingIndMapping/SpecialHandlingIndMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import SpecialHandlingIndMappingMaintenance from "presentation/view/section/SpecialHandlingIndMapping/SpecialHandlingIndMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const SpecialHandlingIndMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.SPECIAL_HANDLING_IND_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <SpecialHandlingIndMappingProvider>
                <GridStyles/>
                <SpecialHandlingIndMappingMaintenance/>
            </SpecialHandlingIndMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default SpecialHandlingIndMappingContMain;